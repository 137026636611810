import { EntityInterface } from '../services/entity.service';
import { Fiscal } from '../interfaces/entity-interfaces';

export type FiscalOwnerType = 'individual'|'legal_person';
export type FiscalStatus = 'created'|'registered_cr'|'registered_certificate'|'active'|'suspended'|'expired'|'deactivated';
export type FiscalPurpose = 'for_use'|'training'|'other';
export type FiscalPlaceType = 'device'|'other';

export const fiscal = {
  name: 'fiscal',
  params: {
    entityConfig: {
      name: 'fiscal',
      get: [
        'getServiceOrganization',
      ],
      post: [
        'getBusinessAddress',
        'registerCrCertificate',
        'registerCr',
        'createCrContainer',
        'updateCertificate',
        'suspend',
        'deregister',
        'activateCr',
        'updateSystemOption',
        'updatePaymentType',
        'updateVat',
      ],
      ownerTypes: [
        'individual',
        'legal_person',
      ],
      statuses: [
        'created',
        'registered_cr',
        'registered_certificate',
        'active',
        'suspended',
        'expired',
        'deactivated',
      ],
      types: [
        'restaurant',
        'common',
      ],
      purposes: [
        'for_use',
        'training',
        'other',
      ],
      placeTypes: [
        'device',
        'other',
      ],
      paymentTypes: [
        'cash',
        'card',
        'loyalty_card',
        'coupon',
        'bank_transaction',
        'nhs_compensated',
        'state_compensated',
        'cryptocurrency',
        'other',
        'round',
      ],
    },
  }
};

export interface FiscalEntityInterface extends EntityInterface<Fiscal> {
  create: (data: any) => Promise<any>;
  getServiceOrganization: () => Promise<any>;
  getBusinessAddress: (data: { personCode: string, name?: string, surname?: string }) => Promise<any>;
  registerCrCertificate: (data: { id: number }) => Promise<any>;
  createCrContainer: (data: { id: number }) => Promise<any>;
  updateCertificate: (data: { id: number }) => Promise<any>;
  suspend: (data: { id: number }) => Promise<any>;
  deregister: (data: { id: number }) => Promise<any>;
  registerCr: (data: { id: number }) => Promise<any>;
  activateCr: (data: { id: number }) => Promise<any>;
  updateSystemOption: (data: any) => Promise<any>;
  updatePaymentType: (data: any) => Promise<any>;
  updateVat: (data: any) => Promise<any>;
}
