import { Action, createReducer, on } from '@ngrx/store';
import { setCurrUser, setEntityFilters, setMinimalMenu, setUiPrefs, userLogout } from './curr-user.actions';
import { CurrUser } from '../../interfaces/object-interfaces';
import { Specialisation, UserMenu, UserMenuGroup } from '../../interfaces/entity-interfaces';
import { CurrUserStateType } from '../currency';

export const initialState: CurrUserStateType = {
    data: null,
    entityFilters: {},
    uiPrefs: {  },
    userMenu: { unassigned: [], groups: [] }
};

const parseUserData = (userData: CurrUser): { data: CurrUser; entityFilters: { [key: string]: unknown } } => {
    const entityFilters: { [entity: string]: unknown } = {};

    if (userData && !!userData.person?.specialisations) {
        (userData.person.specialisations as Specialisation[]).forEach((spec) => {
            if (spec.entityFilters) {

                spec.entityFilters
                    .filter((filter) => !!filter)
                    .forEach((filter) => {
                        const entity = filter.entity;
                        try {
                            const value: { [key: string]: any } = JSON.parse(filter.filter);

                            if (!entityFilters[entity]) entityFilters[entity] = {};
                            for (let key of Object.keys(value)) {
                                entityFilters[entity][key] = entityFilters[entity][key] ?
                                    `${entityFilters[entity][key]}|${value[key]}` : value[key];
                            }

                        } catch (e) {
                            console.warn('Wrong json format for entity filters:', filter.filter)
                        }

                    });

            }
        });

    }

    return {
        data: userData ? {
            ...userData,
            isAgent: userData && userData.type === 'agent',
            isCustomer: userData && userData.type === 'customer',
            isEmployee: userData && userData.type === 'employee',
        } : null,
        entityFilters
    };
};

export const currUserReducer = createReducer(
    initialState,
    on(setCurrUser, (state: CurrUserStateType, action: { payload: CurrUser }) => {
        return {  ...state, ...parseUserData(action.payload) }
    }),
    on(setEntityFilters, (state: CurrUserStateType, action: { payload: { [key: string]: any } }) => {
        return {  ...state, entityFilters: action.payload }
    }),
    on(setUiPrefs, (state: CurrUserStateType, action: { payload: { [key: string]: any } }) => {
        return {  ...state, uiPrefs: { ...state.uiPrefs, ...action.payload } }
    }),
    on(setMinimalMenu, (state: CurrUserStateType, action: { payload: { unassigned: UserMenu[], groups: UserMenuGroup[] } }) => {
        return {  ...state, userMenu: action.payload }
    }),
    on(userLogout, (state: CurrUserStateType) => ({ ...initialState }))
);

export function reducer(state: CurrUserStateType, action: Action): CurrUserStateType {
    return currUserReducer(state, action);
}
