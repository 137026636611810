import { EntityInterface } from '../services/entity.service';
import { Shipment } from '@proman/interfaces/entity-interfaces';

export const shipment = {
    name: 'shipment',
    params: {
        searchFields: [
            'order.number',
            'order.name',
            'order.customerNumber',
            'trackingNumber'
        ],
        entityConfig: {
            name: 'shipment',
            post: [
                'send',
                'updateDate',
                'removeShipmentProduct',
                'createContainer',
                'track',
                'createInvoiceFromShipment',
                'addShipmentProduct',
            ],
            attrs: {
                PLANNED: 'planned',
                SENT: 'sent',
                PREPARED: 'prepared',
                CANCELED: 'canceled',
                SUSPENDED: 'suspended',
                DISPATCHED: 'dispatched',
                DELIVERED: 'delivered',
            }
        },
        get: {
            join: [
                'carrier',
                'shipmentContainers',
                'shipmentContainers.carrier',
                'shipmentDelayType',
                'files',
                'order',
                'invoice',
                'invoice.document',
                'invoice.document.template',
                'documents',
                'documents.file',
                'tags',
                'shippingMethod',
            ]
        },
        extraParameters() {
        }
    }
};

export interface ShipmentEntityInterface extends EntityInterface<Shipment> {
    PLANNED: 'planned';
    SENT: 'sent';
    PREPARED: 'prepared';
    CANCELED: 'canceled';
    SUSPENDED: 'suspended';
    DISPATCHED: 'dispatched';
    DELIVERED: 'delivered';
    track: (data: { id: number }) => Promise<any>;
    send: (data: { id: number|number[] }) => Promise<any>;
    updateDate: (data: { id: number; shipmentDelayType: string; date: string }) => Promise<unknown>;
    removeShipmentProduct: (data: { shipmentProductId: number }) => Promise<unknown>;
    createContainer: (data: { id: number }) => Promise<unknown>;
    createInvoiceFromShipment: (data: { id: number|number[] }) => Promise<unknown>;
    addShipmentProduct: (data: { orderProductId: number; shipmentId: number }) => Promise<unknown>;
}
