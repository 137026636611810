import { EntityInterface } from '@proman/services/entity.service';
import { Supplier } from '@proman/interfaces/entity-interfaces';

export const supplier = {
    name: 'supplier',
    params: {
        searchFields: [
           'id',
            'name'
        ],
        entityConfig: {
            name: 'supplier',
            get: [
                'getSupplierTypes',
            ],
        }
    }
};

export interface SupplierEntityInterface extends EntityInterface<Supplier> {
    getSupplierTypes: () => Promise<string[]>;
}
