import { Injectable } from '@angular/core';
import { Subject } from '@proman/rxjs-common';

const $localStorage = window.localStorage;

@Injectable({ providedIn: 'root' })
export class PreferencesService {
    language: any;
    currency: any;
    tokens: any;
    users: any;
    intendedLocation: any;
    notificationsRowLimit: any;
    notificationsMode: any;
    version: any;
    // pos items below
    customerTable: any;
    customerManager: any;
    lastUsedToken: any;
    rememberLogin: any;

    methods: string[] = [
        'language',
        'currency',
        'tokens',
        'users',
        'notificationsRowLimit',
        'notificationsMode',
        'intendedLocation',
        'version',
        'customerTable',
        'customerManager',
        'lastUsedToken',
        'rememberLogin',
    ];

    resize$: Subject<number> = new Subject<number>();

    constructor() {
        for (let method of this.methods) {
            this[method] = this.getMethod(`prefs.${method}`);
        }

        window.onresize = () => this.resize$.next(Date.now());
    }

    getterSetter = (key: string, value: any) => {
        if (typeof value !== 'undefined') {
            // catch Quota Exceeded Error
            try {
                if (!(typeof value === 'string')) {
                    $localStorage.setItem(key, JSON.stringify(value));
                } else {
                    $localStorage.setItem(key, value);
                }
            } catch (e) {
                return false;
            }

        } else {
            try {
                value = $localStorage.getItem(key);
            } catch (e) {
                value = null;
            }

            try {
                return JSON.parse(value);
            } catch (e) {
                return value;
            }
        }
    };

    getMethod = (key: string) => (value: any) => this.getterSetter(key, value);
}
