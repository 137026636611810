import { EntityInterface } from '@proman/services/entity.service';

export const ocr = {
    name: 'ocr',
    params: {
        entityConfig: {
            name: 'ocr',
            post: ['recognize', 'saveTemplate', 'recognizeByTemplate', 'recognizeIndentificationDocument']
        }
    }
};

export interface OcrEntityInterface extends EntityInterface {
    recognize: (data: { newId: string; type: string; x: number; y: number; height: number; width: number }) => Promise<any>;
    saveTemplate: (data: { [key: string]: any }) => Promise<{ data: number }>;
    recognizeByTemplate: (data: { [key: string]: any }) => Promise<{ data: number }>;
    recognizeIndentificationDocument: (data: any) => Promise<unknown>;
}
