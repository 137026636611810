import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class PromanTranslationService {

    constructor(
        private Translate: TranslateService,
    ) {
    }

    translate = (value: any, params?: any) => {
        if (!value || !(typeof value === 'string')) return value;

        let label: string = this.Translate.instant(this.interpolateString(value, params), params);
        if (label ===  '') label = (value.charAt(0).toUpperCase() + value.slice(1)).replace(/_/g, " ");
        return label;
    };

    interpolateString(main: string, params: any) {

        if ((params && typeof params === 'object') && Object.keys(params).length) {
            for (const key in params) {
                main = main.replace(`{{ ${key} }}`, params[key]);
            }
        }

        return main;
    }

}
