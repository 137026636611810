import { Injectable } from '@angular/core';
import { CONFIG } from '../config';
import { PreferencesService } from './preferences.service';
import { ACL } from './acl.service';
import { RequestService } from './request.service';
import { COOKIE_AUTHORIZATION, CookiesService } from '@proman/services/cookies.service';
import { CurrUser, LoginProject, LoginRequestType } from '@proman/interfaces/object-interfaces';
import { setCurrUser } from '@proman/store/curr-user';
import { Store } from '@ngrx/store';
import { ToastService } from '@proman/services/toast.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private token: string;
    private sessionToken: string;
    userData: CurrUser;

    constructor(
        private ACL: ACL,
        private Request: RequestService,
        private Prefs: PreferencesService,
        private store: Store,
        private Cookies: CookiesService,
        private Toast: ToastService,
    ) {
        this.setToken(this.Cookies.get(COOKIE_AUTHORIZATION));
    }

    init() {

    }

    setToken(token: string|null) {
        this.token = token;
    }

    getToken() {
        return this.token;
    }

    setSessionToken(token: string|null) {
        this.sessionToken = token;
    }

    getSessionToken() {
        return this.sessionToken;
    }

    checkLogin = (params: LoginRequestType): Promise<any> => {
        return this.Request
            .post(`${CONFIG.api}login`, params)
            .then((response: any) => {
                this.userData = response;

                this.setToken(response.token);
                this.Cookies.set(COOKIE_AUTHORIZATION, this.token);

                return response;
            })
            .catch((err) => this.Toast.pop('error', err.error.message));
    };

    handleTokenChange = (token: string, username: string) => {
        let persistedTokens = this.Prefs.tokens() || {};

        this.setToken(token);

        // add current token to token map
        persistedTokens[username] = token;

        // flush token map to storage
        this.Prefs.tokens(persistedTokens);

        // mark current token as last used
        this.Cookies.set(COOKIE_AUTHORIZATION, token);
    };

    login = (credentials: LoginRequestType, project: LoginProject = LoginProject.FRONTEND, isUserPassCheck?: boolean, twoFactorAuth: boolean = true): Promise<void> => {
        return this
            .checkLogin({ ...credentials, project })
            .then((response: any) => {
                if (!response) return;
                if (response.mustChangePassword || (twoFactorAuth && response.twoFactorLoginEnabled)) {
                    return response;
                }

                this.handleTokenChange(response.token, response.username);
                this.handleAuthorisation(isUserPassCheck);

                return response;
            });
    };

    switchToken = (token: string, username: string) => {
        this.handleTokenChange(token, username);
    };

    clearToken = () => {
        this.setToken(null);
    };

    handleAuthorisation = (isUserPassCheck?: any, reload?: any) => {
        this.store.dispatch(setCurrUser({ payload: this.userData }));
    };

}
