import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from '@proman/rxjs-common';
import {
  loadSystemOptions, setNamespaces,
  setPublicSystemOptions,
  setSystemOptions,
  SystemOptionsActions,
} from './system-options.actions';
import { Entity, EntityInterface } from '../../services/entity.service';
import { PublicSystemOptions, SystemOptions } from '../../interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { CONFIG } from '../../config';
import { isDefinedNotNull } from '@proman/utils';
import { PreferencesService } from '../../services/preferences.service';
import moment from 'moment';
import {AuthService} from "@proman/services/auth.service";

@Injectable()
export class SystemOptionsEffects {
    systemOptionsEntity: EntityInterface;

    updateSystemOptions$ = createEffect(() => this.actions$
            .pipe(
                ofType(SystemOptionsActions.UpdateData),
                tap((action: { payload: { key: keyof SystemOptions; value: any } }) => {
                    const key = action.payload.key;
                    const value = action.payload.value?.id || action.payload.value;
                    this.systemOptionsEntity
                        .update({ id: 1, [key]: value })
                        .then(() => {
                            if (key === 'bookkeepingType') {
                                this.store.dispatch(loadSystemOptions());
                            }
                        });
                })
            ),
        { dispatch: false }
    );

    loadSystemOptions$ = createEffect(() => this.actions$
            .pipe(
                ofType(SystemOptionsActions.LoadData),
                tap(() => {
                  if (this.Auth.getToken()) this.Entity.get('system_options')
                        .get({
                            join: [
                                'mainPortalLogo',
                                'logo',
                                'defaultWorkshift',
                                'automaticProformaTemplate',
                                'defaultSpecialisation'
                            ]
                        })
                        .then((payload: SystemOptions) => {
                          // TODO atkomentuoti kai vasaros laikas vėl baigsis
                          // if (moment().isDST() && payload) {
                          //   if (payload.workingDayStartsAt) Object.assign(payload, { workingDayStartsAt: moment(payload.workingDayStartsAt, 'HH:mm:ss').subtract(1, 'h').format('HH:mm:ss') })
                          //   if (payload.workingDayFinishAt) Object.assign(payload, { workingDayFinishAt: moment(payload.workingDayFinishAt, 'HH:mm:ss').subtract(1, 'h').format('HH:mm:ss') })
                          // }
                          this.store.dispatch(setSystemOptions({ payload }));
                        });
                })
            ),
        { dispatch: false }
    );

    loadPublicSystemOptions$ = createEffect(() => this.actions$
            .pipe(
                ofType(SystemOptionsActions.LoadPublicData),
                tap(() => {
                    return fetch(`${CONFIG.api}public/system_options`)
                        .then((response: any) => response.json())
                        .then((response: PublicSystemOptions) => {

                            const currentVersion = response.version;
                            const version = this.Prefs.version();

                            if (!isDefinedNotNull(version)) { // first time connections
                                this.Prefs.version(currentVersion);

                            } else if (version !== currentVersion) { // reload if new build
                                this.Prefs.version(currentVersion);
                                return window.location.reload();

                            }
                            this.store.dispatch(setPublicSystemOptions({ payload: response }));
                        });
                })
            ),
        { dispatch: false }
    );

    loadNamespaces$ = createEffect(() => this.actions$
      .pipe(
        ofType(SystemOptionsActions.LoadNamespaces),
        tap(() => {
          this.Entity.get('corporate_namespace').search({ join: ['children'] }).then((payload) => {
            this.store.dispatch(setNamespaces({ payload }));
          })
        })
      ), { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private Entity: Entity,
        private Prefs: PreferencesService,
        private Auth: AuthService,
    ) {
        this.systemOptionsEntity = Entity.get('system_options');
    }

}
