import { EntityInterface } from '@proman/services/entity.service';
import { dateTime } from '@proman/interfaces/common.interface';

export const pos_account = {
    name: 'pos_account',
    params: {
        entityConfig: {
            name: 'pos_account',
            post: [
                'printReceipt',
                'openDrawer',
                'payment',
                'createReport',
                'cashRegistryOperation',
                'createReportByDate',
                'createReportBy',
                'managerReport',
                'nextMeal',
                'checkReturn',
                'deviceStatus',
            ]
        }
    }
};

export interface PosAccountEntityInterface extends EntityInterface {
    printReceipt: (data: { order: number; id: number }) => Promise<any>;

    nextMeal: (data: { id: number }) => Promise<any>;

    managerReport: (data: { device: number; ids: number[] }) => Promise<any>;

    payment: (data: { device: number; order: number; customer: number; payments: any }) => Promise<any>;

    createReportBy: (data: {
        report_by: string;
        from_id?: number;
        to_id?: number;
        longReport: boolean;
        eksReport: boolean;
        device: number;
        from_date?: string;
        to_date?: string;
        printReport?: boolean;
    }) => Promise<any>;

    checkReturn: (data: {
        device: number;
        unp?: number;
        order?: number;
        time?: dateTime;
        waiterError?: boolean;
        checkReturn?: boolean;
        sum?: number;
        number?: number;
    }) => Promise<any>;

    deviceStatus: (data: any) => Promise<any>;
}
