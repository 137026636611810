import { EntityInterface } from '../services/entity.service';
import { Purchase, PurchaseCategory, Region } from '@proman/interfaces/entity-interfaces';
import { TableField } from '@proman/interfaces/object-interfaces';
import { Money } from '@proman/interfaces/common.interface';

const CONFIRMED = 'confirmed';
const CLOSED = 'closed';
const UNCONFIRMED = 'unconfirmed';
const RECEIVED = 'received';
const RETURNED = 'returned';
const STORNO = 'storno';

const PRODUCT: PurchaseTypes = 'products';
const SERVICE: PurchaseTypes = 'services';
const EXPENSES: PurchaseTypes = 'expenses';
const ASSETS: PurchaseTypes = 'assets';
const MATERIAL: PurchaseTypes = 'materials';
const INVENTORY: PurchaseTypes = 'inventory';

export type PurchaseTypes = 'products'|'services'|'assets'|'expenses'|'materials'|'inventory';

export const PURCHASE_TYPE = {
    PRODUCT,
    SERVICE,
    EXPENSES,
    ASSETS,
    MATERIAL,
    INVENTORY,
};

export const purchase = {
    name: 'purchase',
    params: {
        entityConfig: {
            name: 'purchase',
            post: [
                'receive',
                'return',
                'createWithPositions',
                'sendEmail',
                'evaluatePurchaseCost',
                'removeProduct',
                'copy',
                'updatePaymentStatus',
                'addLedgerRecord',
                'saveTemplate',
                'setSuppliersDebt',
                'createPayout',
                'purchaseImport'
            ],
            get: ['materialDelayedArrivals', 'getPurchaseTypes'],
            attrs: {
                STATUS_UNCONFIRMED: UNCONFIRMED,
                STATUS_CONFIRMED: CONFIRMED,
                STATUS_CLOSED: CLOSED,
                STATUS_RECEIVED: RECEIVED,
                STATUS_RETURNED: RETURNED,
                STATUS_STORNO: STORNO,
            }
        },
        getFields: (translateFn: (value?: string) => string, categories?: PurchaseCategory[]): TableField[] => {
            return [
                {
                    name: 'number',
                    key: 'id'
                },
                {
                    name: 'info.created',
                    key: 'createdAt',
                    getValue: (row: Purchase) => row.createdAt ?
                        `${row.createdBy && row.createdBy.firstName || ''}: ${row.createdAt.substr(0, 10)}` :
                        null,
                    filter: { type: 'date' },
                },
                {
                    name: 'purchased',
                    key: 'product.material.alias',
                    getValue: (purchase: Purchase) => {
                        const materials = {};
                        purchase?.quants && purchase.quants.forEach((product) => materials[product.material.alias] = true);
                        purchase?.products && purchase.products.forEach((product) => materials[product.name] = true);
                        return Object.keys(materials).join(', ');
                    },
                    filter: {
                        type: 'search',
                        keys: ['quants.material.alias', 'products.name'],
                    },
                    maxLength: 50
                },
                {
                    key: 'importDate',
                    name: 'import_date',
                    formatter: 'dateTime',
                },
                {
                    key: 'expectedArrival',
                    name: 'expected_arrival_date',
                    formatter: 'dateTime',
                },
                {
                    name: 'category',
                    key: 'category.id',
                    getValue: (item: Purchase) => item.category?.name,
                    extraJoins: ['category'],
                    hidden: true,
                    filter: {
                        type: 'dropdown_multi',
                        options: categories
                    }
                },
                {
                    name: 'purchase_type',
                    key: 'type'
                },
                {
                    name: 'invoice_total',
                    key: 'invoiceTotal',
                    stats: ['sum'],
                    formatter: 'money',
                    hidden: true,
                    acl: 'purchase.show_price',
                },
                {
                    name: 'invoice_vat',
                    key: 'invoiceVatSum',
                    stats: ['vat'],
                    formatter: 'money',
                    hidden: true,
                    acl: 'purchase.show_price',
                },
                {
                    name: 'invoice_sum',
                    key: 'invoiceTotal',
                    stats: ['sum'],
                    formatter: 'money',
                    acl: 'purchase.show_price',
                },
                {
                    name: 'local_currency_total',
                    key: 'invoiceTotalLocalCurrency',
                    stats: ['sum'],
                    formatter: 'money',
                    acl: 'purchase.show_price',
                },
                {
                    name: 'invoice_number',
                    key: 'invoiceNumber'
                },
                {
                    name: 'invoice_date',
                    key: 'date',
                    formatter: 'dateTime',
                    formatterConfig: '_datetime_js',
                },
                {
                    name: 'supplier',
                    key: 'supplier.name'
                },
                {
                    name: 'supplier_country',
                    key: 'supplier.country.name',
                },
                {
                    name: 'region',
                    key: 'supplier.country.regions',
                    getValue: (purchase: Purchase) => {
                        let region = '';
                        if (purchase?.supplier?.country?.regions?.length > 0) {
                            region = purchase.supplier.country.regions.map((region: Region) => region?.name).join(', ');
                        }
                        return region;
                    },
                    sort: false,
                    filter: {
                        type: 'autocomplete',
                        entity: 'region'
                    },
                    hidden: true
                },
                {
                    name: 'comments',
                    key: 'comments',
                    getValue: (row: Purchase) => row.comments && row.comments.split(/<[^>]*>/).join(''),
                },
                {
                    name: 'pay_due',
                    key: 'payDue',
                    formatter: 'dateTime',
                },
                {
                    name: 'paid_sum',
                    key: 'paid',
                    acl: 'purchase.show_price',
                    sortable: false,
                    getValue: (purchase: Purchase) => Object.assign({}, {
                        'amount': purchase.payments?.reduce((a, b) => a + +b.sum.amount, 0) ?? 0,
                        'currency': purchase.invoiceTotal?.currency
                    }),
                    stats: ['sum'],
                    formatter: 'money'
                },
                {
                    name: 'remnant',
                    key: 'remnant',
                    acl: 'purchase.show_price',
                    sortable: false,
                    getValue: (purchase: Purchase) => Object.assign({}, {
                        'amount': +purchase.invoiceTotal?.amount - purchase.payments?.reduce((a, b) => a + +b.sum.amount, 0) ?? 0,
                        'currency': purchase.invoiceTotal?.currency
                    }),
                    stats: ['sum'],
                    formatter: 'money'
                },
                {
                    name: 'is_paid',
                    key: 'isPaid',
                    formatter: 'iconBoolean',
                    getValue: (row: Purchase) => !!row.isPaid || false,
                    filter: {
                        key: 'id',
                        type: 'dropdown',
                        options: [
                            {
                                name: translateFn('yes'),
                                id: true
                            },
                            {
                                name: translateFn('no'),
                                id: false
                            }
                        ]
                    }
                },
                {
                    name: 'status',
                    key: 'status',
                    filter: {
                        type: 'dropdown',
                        options: [CONFIRMED, UNCONFIRMED, RECEIVED, STORNO]
                            .map((item) => ({ name: translateFn(item), id: `=${item}` })),
                        key: 'id',
                    },
                    translate: true
                },
            ]
        }
    }
};

export interface PurchaseEntityInterface extends EntityInterface<Purchase> {
    STATUS_UNCONFIRMED: string;
    STATUS_CONFIRMED: string;
    STATUS_CLOSED: string;
    STATUS_RETURNED: string;
    STATUS_STORNO: string;
    STATUS_RECEIVED: string;
    sendEmail: (data: {  }) => Promise<any>;
    receive: (data: any) => Promise<any>;
    return: (data: any) => Promise<any>;
    getPurchaseTypes: () => Promise<string[]>;
    setSuppliersDebt: (data?: { date?: string }) => Promise<any[]>;
    createWithPositions: (data: any) => Promise<any>;
    removeProduct: (data: any) => Promise<any>;
    evaluatePurchaseCost: (data: { id: number }) => Promise<any>;
    addLedgerRecord: (data: { id: number }) => Promise<any>;
    materialDelayedArrivals: (data: any) => Promise<any[]>;
    copy: (data: { id: number; preserveProductsQuantities: boolean; returnOrder?: boolean }) => Promise<any>;
    create: (data: { type?: PurchaseTypes; supplier?: number; [key: string]: any }) => Promise<{ data: number }>;
    createPayout: (data: {
        id: number;
        payoutSum?: number;
        date?: string;
        categoryDbitId?: number;
        categoryCrdtId?: number;
        typeCrdt?: string;
        typeDbit?: string;
    }) => Promise<any>;
    updatePaymentStatus: (
        data: {
            id: number;
            paidAmountCrdt: Money|string;
            paidAmountDbit: Money|string;
            paidAt: string;
            bankTransactionId?: number;
            categoryDbitId?: number;
            categoryCrdtId?: number;
            typeCrdt?: string;
            typeDbit?: string;
        }) => Promise<void>;
    purchaseImport: (data: any) => Promise<void>;
}
