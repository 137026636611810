import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIG } from '../config';
import { Store } from '@ngrx/store';
import { setJsonRequest } from '@proman/store/utils';
import { isDefinedNotNull } from '@proman/utils';
import { params as buildParams } from '@proman/utils-params';
import { loadLanguage, overrideTranslations } from '@translations/json-language-loader';

@Injectable({ providedIn: 'root' })
export class RequestService {
    token: string;

    constructor(
        public http: HttpClient,
        private injector: Injector,
        private store: Store,
    ) {}

    post<T = any>(url: string, data?: any): Promise<T> {
        function getFormData(params: any) {
            const paramArray: string[] = [];

            for (const key in params) {
                paramArray.push(buildParams({ [key]: params[key] }));
            }
            return paramArray.join('&');
        }

        return new Promise((resolve, reject) => {

            if (getFormData(data).length > 5000) {
                return resolve(this.postJson(url, data));

            } else {
                this.http.post(url, getFormData(data))
                    .subscribe((response: any) => resolve(response), (error) => {

                        if (error.status === 200 && error.error && error.error.text) {
                            resolve(error.error.text);

                        } else {
                            if (error.status === 400) console.error(`${error.message}`);
                            reject(error);
                        }

                    });
            }
        });
    }

    loadLanguageWithCustom(lang: string): Promise<{[key: string]: string}> {
        if (!lang) lang = 'en';
        return new Promise((resolve) => {
            this.get(`${CONFIG.root}api/public/custom_translation/${lang}`)
                .then((responseCustom) => {
                    loadLanguage(lang)
                        .then(() => {
                            resolve(overrideTranslations(responseCustom));
                        });

                })
                .catch((err) => {
                    console.warn(`Err loading ${lang} language translations`);
                });

        });
    }


    postJson<T = any>(url: string, data?: any): Promise<T> {

        return new Promise((resolve, reject) => {
            this.store.dispatch(setJsonRequest({ payload: url }))
            this.http.post(url, JSON.stringify(data || {}))
                .subscribe((response: any) => resolve(response), (error) => {

                    if (error.status === 200 && error.error && error.error.text) {
                        resolve(error.error.text);

                    } else {
                        if (error.status === 400) console.error(`${error.message}`);
                        reject(error);
                    }

                });
        });
    }

    get<T = any>(url: string): Promise<T> {
        return new Promise((resolve, reject) => {

            if (url.includes('api/jsonws/invoke')) {
                reject('api/jsonws/invoke trigger');
                throw new Error('api/jsonws/invoke trigger');
            } else {
                this.http.get(url)
                    .subscribe((response: any) => resolve(response), (error) => reject(error));
            }
        });

    }

    setAuthHeader(token: string) {
        this.token = `Bearer ${token}`;
    }

    getUploadHeaders(config?: any) {
        let headers: any = { 'X-Requested-With': 'XMLHttpRequest' };

        if (this.token) headers.Authorization = this.token;

        if (config) Object.assign(headers, config);

        if (config) Object.keys(config.headers).forEach((key) => {
                if (typeof config.headers[key] === 'function') {
                    if (isDefinedNotNull(config.headers[key]())) {
                        Object.assign(headers, {[key]: config.headers[key]()});
                    }
                } else {
                    if (isDefinedNotNull(config.headers[key])) {
                        Object.assign(headers, {[key]: config.headers[key]});
                    }
                }
        })

        return  { headers: new HttpHeaders(headers), reportProgress: true };
    }

    loadUser() {
        return this.get(`${CONFIG.api}user`).catch(() => {});
    }

}
