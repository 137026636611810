let translations: { [key: string]: string } = {};

export const loadLanguage = (lang: string) => {

    let translationsPromise: any;

    // require vietoje import, nes nemate quant_event.* raktu.
    switch(lang) {
        case 'bg':
            translationsPromise = require('./bg/global.json');
            break;
        case 'ch':
            translationsPromise = require('./ch/global.json');
            break;
        case 'de':
            translationsPromise = require('./de/global.json');
            break;
        case 'en':
            translationsPromise = require('./en/global.json');
            break;
        case 'es':
            translationsPromise = require('./es/global.json');
            break;
        case 'fr':
            translationsPromise = require('./fr/global.json');
            break;
        case 'lt':
            translationsPromise = require('./lt/global.json');
            break;
        case 'pl':
            translationsPromise = require('./pl/global.json');
            break;
        case 'ru':
            translationsPromise = require('./ru/global.json');
            break;
        case 'tr':
            translationsPromise = require('./tr/global.json');
            break;
        case 'ua':
            translationsPromise = require('./ua/global.json');
            break;
        case 'cn':
            translationsPromise = require('./zh/global.json');
            break;
        case 'vn':
            translationsPromise = require('./vn/global.json');
            break;
        default:
            translationsPromise = require('./en/global.json');

    }

    translations = translationsPromise;

    const result = new Promise((resolve) => resolve(translations));
    return result;
};

export const loadErrorsTranslations = (lang: string) => {
    let translationsPromise: Promise<any>;

    switch(lang) {
        case 'ch':
            translationsPromise = import('./ch/errors.json');
            break;
        case 'lt':
            translationsPromise = import('./lt/errors.json');
            break;
        case 'ru':
            translationsPromise = import('./ru/errors.json');
            break;
        case 'tr':
            translationsPromise = import('./tr/errors.json');
            break;
        case 'bg':
        case 'de':
        case 'en':
        case 'es':
        case 'fr':
        case 'pl':
        case 'ua':
        case 'cn':
        default:
            translationsPromise = import('./en/errors.json');

    }

    return translationsPromise
        .then((_translations) => {
            translations = _translations;
            return _translations;
        });
};

export const getCurrentTranslations = () => translations;

export const overrideTranslations = (data: { [key: string]: string }) => {
    translations = { ...translations, ...data };

    return translations;
};
