import { EntityInterface } from '@proman/services/entity.service';

export const employee_contract = {
    name: 'employee_contract',
    params: {
        entityConfig: {
            name: 'employee_contract',
            get: [
                'nextContractNumber',
                'usedVacationDays',
                'contractVacationDays',
                'getContract',
                'averagePerDay',
            ],
            post: [
                'save',
                'vacationRequest',
                'vacationAppend',
                'vacationRegenerate',
                'cancelVacations',
                'createVacationByDate',
                'addVacation',
                'createTrip',
            ]
        }
    }
};

export interface EmployeeContractEntityInterface extends EntityInterface {
    nextContractNumber: () => Promise<number>;
    usedVacationDays: (data: { employeeId: number; start: string; end: string }) => Promise<number>;
    contractVacationDays: (data: { employeeId: number; date: string }) => Promise<number>;
    getContract: (data: { employeeId: number; date?: string }) => Promise<number>;
    save: (data: { contractLogId: number }) => Promise<number>;
    vacationRegenerate: (data: { id: number }) => Promise<void>;
    vacationAppend: (data: { vacation: number, template: number }) => Promise<any>;
    cancelVacations: (data: { id: number }) => Promise<void>;
    averagePerDay: (data: { employee: number, year: string, month: string }) => Promise<number>;
    vacationRequest: (data: { startDate: string; duration: number; employeeId: number; template: number; unpaid: boolean; type: string }) => Promise<any>;
    addVacation: (data: { id: number }) => Promise<any>;
    createTrip: (data: any) => Promise<any>;
    createVacationByDate: (data: { from: string; to: string; employees: number[]; template: number; template2: number; template3: number; unpaid: boolean }) => Promise<any>;
}
