import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { Subject } from '@proman/rxjs-common';

export type PermissionType = 'agent.create'|
'agent.display'|
'agent.edit'|
'agent.remove'|
'agent.view'|
'article.confirm'|
'article.view'|
'article.create'|
'article.display'|
'article.edit'|
'article.remove'|
'article.master'|
'article_test.display'|
'article_test.view'|
'article_test.edit'|
'article_test.remove'|
'article_test.create'|
'auth_token.view'|
'boards.create'|
'boards.display'|
'boards.edit'|
'boards.remove'|
'boards.view'|
'calendar.create'|
'calendar.edit'|
'calendar.remove'|
'calendar.view'|
'camera.display'|
'camera.edit'|
'camera.view'|
'carrier.create'|
'carrier.display'|
'carrier.edit'|
'carrier.remove'|
'carrier.view'|
'chart.display'|
'chart.edit'|
'chart.view'|
'consumer.create'|
'consumer.edit'|
'consumer.update_price'|
'consumer.remove'|
'consumer.view'|
'consumer.display'|
'consumer.view_all'|
'consumer_booking.create'|
'consumer_booking.edit'|
'consumer_booking.remove'|
'consumer_booking.view'|
'customer.create'|
'customer.display'|
'customer.edit'|
'customer.remove'|
'customer.view'|
'customer.view_all'|
'customer_employee.create'|
'customer_employee.display'|
'customer_employee.edit'|
'customer_employee.remove'|
'customer_employee.view'|
'customer_employee.view_all'|
'development_project.create'|
'development_project.details'|
'development_project.display'|
'development_project.edit'|
'development_project.remove'|
'development_project.view'|
'development_project.view_all'|
'dynamic.create'|
'dynamic.display'|
'dynamic.edit'|
'dynamic.remove'|
'dynamic.view'|
'dynamic.view_all'|
'employee.create'|
'employee.show_price'|
'employee.display'|
'employee.edit'|
'employee.remove'|
'employee.view'|
'employee.view_all'|
'employee.master'|
'event.create'|
'event.confirm'|
'event.display'|
'event.edit'|
'event.remove'|
'event.update_status'|
'event.view'|
'event.view_all'|
'event.master'|
'filter.edit'|
'filter.view'|
'formula.display'|
'formula.edit'|
'formula.view'|
'improvement_message.display'|
'improvement_message.edit'|
'improvement_message.view'|
'improvement_type.display'|
'improvement_type.edit'|
'improvement_type.view'|
'invoice.create'|
'invoice.display'|
'invoice.edit'|
'invoice.remove'|
'invoice.update_status'|
'invoice.view'|
'invoice.view_all'|
'invoice.master'|
'maintenance.create'|
'maintenance.display'|
'maintenance.edit'|
'maintenance.remove'|
'maintenance.view'|
'material.confirm'|
'material.create'|
'material.display'|
'material.edit'|
'material.update_status'|
'material.remove'|
'material.show_price'|
'material.view'|
'material.master'|
'material_category.create'|
'material_category.display'|
'material_category.edit'|
'material_category.remove'|
'material_category.view'|
'purchase.create'|
'purchase.display'|
'purchase.edit'|
'purchase.master'|
'purchase.remove'|
'purchase.show_price'|
'purchase.view'|
'notification.create'|
'notification.view'|
'order.confirm'|
'order.create'|
'order.display'|
'order.edit'|
'order.master'|
'order.remove'|
'order.show_price'|
'order.update_price'|
'order.update_status'|
'order.view'|
'order.view_all'|
'order_proposal.confirm'|
'order_proposal.create'|
'order_proposal.display'|
'order_proposal.edit'|
'order_proposal.master'|
'order_proposal.remove'|
'order_proposal.show_price'|
'order_proposal.update_price'|
'order_proposal.update_status'|
'order_proposal.view'|
'order_proposal.view_all'|
'patient_appointment.create'|
'patient_appointment.display'|
'patient_appointment.edit'|
'patient_appointment.remove'|
'patient_appointment.view'|
'parameter.create'|
'parameter.display'|
'parameter.edit'|
'parameter.remove'|
'parameter.view'|
'parameter.master'|
'permission.edit'|
'permission.view'|
'printer.display'|
'printer.edit'|
'printer.view'|
'product.create'|
'product.display'|
'product.edit'|
'product.remove'|
'product.show_price'|
'product.view'|
'product.master'|
'production.confirm'|
'production.create'|
'production.display'|
'production.edit'|
'production.remove'|
'production.show_price'|
'production.update_status'|
'production.view'|
'reports.create'|
'reports.edit'|
'reports.remove'|
'reports.view'|
'reports.view_all'|
'reports.master'|
'role.create'|
'role.display'|
'role.edit'|
'role.remove'|
'role.view'|
'sale_opportunity.create'|
'sale_opportunity.display'|
'sale_opportunity.edit'|
'sale_opportunity.remove'|
'sale_opportunity.show_price'|
'sale_opportunity.view'|
'sale_opportunity.view_all'|
'sensor.display'|
'sensor.edit'|
'sensor.view'|
'shipment.create'|
'shipment.display'|
'shipment.edit'|
'shipment.remove'|
'shipment.show_price'|
'shipment.update_status'|
'shipment.view'|
'shipment.master'|
'specialisation.create'|
'specialisation.display'|
'specialisation.edit'|
'specialisation.remove'|
'specialisation.view'|
'specialisation.view_all'|
'subcontractor.create'|
'subcontractor.display'|
'subcontractor.edit'|
'subcontractor.remove'|
'subcontractor.view'|
'supplier.create'|
'supplier.display'|
'supplier.edit'|
'supplier.remove'|
'supplier.view'|
'system_options.edit'|
'system_options.view'|
'system_options.display'|
'tag.edit'|
'templates.create'|
'templates.edit'|
'templates.remove'|
'templates.view'|
'time_options.display'|
'time_options.edit'|
'time_options.view'|
'workgroup.create'|
'workgroup.display'|
'workgroup.edit'|
'workgroup.remove'|
'workgroup.view'|
'workplace.create'|
'workplace.display'|
'workplace.edit'|
'workplace.remove'|
'workplace.view'|
'login_block.create'|
'login_block.display'|
'login_block.edit'|
'login_block.remove'|
'login_block.view'|
'login_block_file.create'|
'login_block_file.display'|
'login_block_file.edit'|
'login_block_file.remove'|
'login_block_file.view'|
'file.create'|
'file.display'|
'file.edit'|
'file.remove'|
'file.view'|
'payment_report.view'|
'payment_report.display'|
'payment_report.create'|
'payment_report.remove'|
'payment_report.edit'|
'payment_report.master'|
'customer_claim.view'|
'customer_claim.display'|
'customer_claim.create'|
'customer_claim.edit'|
'customer_claim.remove'|
'customer_claim.view_all'|
'customer_claim.master'|
// -------- master acl
'container.view'|
'container.create'|
'container.edit'|
'container.remove'|
'server.view'|
'server.create'|
'server.edit'|
'server.remove'|
'notification.edit'|
'notification.remove'|
'translation.view'|
'translation.create'|
'translation.edit'|
'translation.remove'|
'fiscal.create'|
'fiscal.view'|
'fiscal.edit'|
'fiscal.remove';

@Injectable({ providedIn: 'root' })
export class ACL {
    permissions: {
        [key in PermissionType]: boolean
    };
    isLoaded$: Subject<void> = new Subject();

    constructor(private store: Store) {
        this.store.select(getCurrUser)
            .subscribe((value) => {
               if (value) {
                   this.permissions = value.permissions;
                   this.isLoaded$.next();
               } else {
                   this.drop();
               }
            });
    }

    drop() {
        this.permissions = null;
    }

    check(key: PermissionType|PermissionType[], args?: any): boolean {
        if (!this?.permissions) return false;

        if (typeof args !== 'undefined') {
            // args is provided only for restrictions
            return true;

        }

        if (Array.isArray(key)) {
            // must have all permissions
            return key.every((k) => !!this.permissions[k]);

        }

        return !!this.permissions[key];
    }
    checkOne(key: PermissionType|PermissionType[], args?: any): boolean {
        if (!this?.permissions) return false;

        if (typeof args !== 'undefined') {
            // args is provided only for restrictions
            return true;

        }

        if (Array.isArray(key)) {
            // must have one permission
            return key.some((k) => !!this.permissions[k]);

        }

        return !!this.permissions[key];
    }

}
